import { createApp } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from '@/router';
import dateFilter from '@/filters/date.filter';
import price from '@/filters/price.filter';

import '@/assets/scss/app.scss';

import App from '@/App.vue';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(VueAxios, axios);

app.config.globalProperties.$filters = {
  date: dateFilter,
  price,
}

app.mount('#app');

export const FORMAT_DATE = 'DD.MM.YYYY';
export const FORMAT_DATE_TIME = 'DD.MM.YYYY HH:mm';

export const DOCUMENT_TYPES: string[] = [
  'memo', // Служебная записка
  'contract',
  'act',
  'ks3',
  'act_final',
  'supplementary_agreement',
  'tech_task', // ТЗ на тендер
  'vacancy', // Заявка на подбор персонала
  'percentage',
];
export const ACT_TYPES: string[] = [
  'act',
  'ks3',
  'act_final',
  'supplementary_agreement',
];

import { defineStore } from 'pinia';

type AppState = {
  isPreloaderVisible: boolean;
  rememberedUrl: string;
};

export const REMEMBERED_URL_STORAGE_KEY: string = 'remembered:urls';

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    isPreloaderVisible: false,
    rememberedUrl: '',
  }),
  getters: {
    getRememberedUrl: (state: AppState): string => {
      if (state.rememberedUrl) {
        return state.rememberedUrl;
      }

      const rememberedUrl: string | null = window.localStorage.getItem(REMEMBERED_URL_STORAGE_KEY);

      if (rememberedUrl) {
        return JSON.parse(rememberedUrl);
      }

      return '';
    },
  },
  actions: {
    showPreloader(): void {
      this.isPreloaderVisible = true;
    },
    hidePreloader(): void {
      this.isPreloaderVisible = false;
    },
    setRememberedUrl(url: string): void {
      this.rememberedUrl = url;
      window.localStorage.setItem(REMEMBERED_URL_STORAGE_KEY, JSON.stringify(url));
    },
    removeRememberedUrl(): void {
      this.rememberedUrl = '';
      window.localStorage.removeItem(REMEMBERED_URL_STORAGE_KEY);
    }
  },
});

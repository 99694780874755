import { createRouter, createWebHistory } from 'vue-router';

const TasksPage = () => import('@/pages/TasksPage.vue');
const VacancyPage = () => import('@/pages/VacancyPage.vue');
const DocumentPage = () => import('@/pages/DocumentPage.vue');
const OrderPage = () => import('@/pages/OrderPage.vue');
const LoginPage = () => import('@/pages/LoginPage.vue');

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '',
      name: 'tasks',
      component: TasksPage,
    },
    {
      path: '/documents/:id',
      name: 'document',
      component: DocumentPage,
    },
    {
      path: '/vacancies/:id',
      name: 'vacancy',
      component: VacancyPage,
    },
    {
      path: '/order/:id',
      name: 'order',
      component: OrderPage,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage
    },
  ],
});

export default router;

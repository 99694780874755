/**
 * @project   setl/docs.setlgroup.ru
 * @see       https://git.setl.ru/setl/docs.setlgroup.ru
 * @author    Valery Chyzhykau <chizhikov_vd@setltech.ru>
 * @copyright Copyright 2023 Valery Chyzhykau
 */

import wNumb from 'wnumb';

export default function price(value: string): string {
  const commaIndex = value.indexOf(',');

  let price;

  if(commaIndex !== -1) {
    const arr = value.split('');
    arr.splice(commaIndex, 1, '.');
    price = +arr.join('');
  } else {
    price = +value;
  }

  return wNumb({
    suffix: ' ₽',
    thousand: ' ',
    decimals: 2,
  }).to(price);
}
